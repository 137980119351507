@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins';
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #cfd3d7;
  border-radius: 10px;
  border: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #cfd3d7;
}

::-webkit-scrollbar-track {
  background-color: #cfd3d7;
  border-radius: 20px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #cfd3d7 #ffffff;
}
